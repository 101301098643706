import { isElectron, ipcRenderer } from '../constant';
import router from '@/router';
/**
 * @param {String} path 路径
 * 打开管理后台
 **/
const openManagementSystem = (path) => {
	let token = sessionStorage.getItem('token');
	let route = router.resolve({ path });
	// console.log('route', route);
	let { href } = route;
	href = window.location.origin + href;
	// log
	if (isElectron){
		href = href + '?token=' + token;
		console.log('openExternal', href, ipcRenderer);
		// shell.openExternal(href);
		ipcRenderer.send('openExternal', href);
		// ipcRenderer.send('openExternal', 'http://www.baidu.com');
		// ipcRenderer.send('openExternal', href);
	}
	else {
		window.open(href, "_blank")
	}
}

export {
	openManagementSystem,
}