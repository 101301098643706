
<template>
	<div id="smart-flow"></div>
</template>

<script>
export default {
	name: 'SmartFlow'
}
</script>

<style>

</style>