// electron运行环境下不支持Promise.finally 引入core-js来支持Promise.finally写法
import 'core-js/features/promise/finally';
// import '@/core/Electron';

import Rsa from "@/utils/rsa";
import LabbolBasicComponents from 'labbol-basic-components';
// import LabbolBasicComponents from '../../../../BasicComponents/src/components/index';


export default {
	install(Vue){
		Vue.prototype.Rsa = Rsa;
		Vue.use(LabbolBasicComponents);
	}
}

import {
	useForm, usePageWrapper, useTable, useDialog, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
	LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
    ToolbarUser, ToolbarSubscribeApps,
} from 'labbol-basic-components';
// import {
// 	useForm, usePageWrapper, useTable, useDialog, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
// 	LoginPath, LoginName, Login,
//     RegisterPath, RegisterName, Register,
//     ChangePasswordPath, ChangePasswordName, ChangePassword,
//     ToolbarUser, ToolbarSubscribeApps,
// } from '../../../../BasicComponents/src/components/index';
export {
	useForm, usePageWrapper, useTable, useDialog, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
	LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
    ToolbarUser, ToolbarSubscribeApps,
}
