import SmartFlow from '@/views/SmartFlow';

const routes = [
	{
		path: "/smart-flow",
		name: "smart-flow",
		component: SmartFlow,
		meta: {
			title: "智能化流程",
			groupName: '流程',
			icon: "HomePage",
		},
	},
]

export default routes;