import Vue from 'vue';
// import productCustomFormView from "../../../../product-custom-form-view";
import productCustomFormView from "product-custom-form-view";
Vue.use(productCustomFormView);

// import { Index, Design, CustomFormBusiness } from "../../../../product-custom-form-view";
import { Index, Design, CustomFormBusiness } from "product-custom-form-view";


const routes = [
	// {
	// 	path: "/custom-form",
	// 	name: "CustomForm",
	// 	component: Index,
	// 	meta: {
	// 		title: "自定义表单",
	// 		groupName: '表单',
	// 		icon: "HomePage",
	// 	},
	// },
	{
		path: "/custom-form-business",
		name: "CustomFormBusiness",
		component: CustomFormBusiness,
		meta: {
			title: "自定义业务表单",
			groupName: '表单',
			icon: "HomePage",
		},
	},
	{
		path: "/custom-form-design",
		name: "CustomFormDesign",
		component: Design,
		meta: {
			hideMenu: true,
			currentActiveMenu: '/custom-form-business',
			// title: "自定义表单-设计",
			// groupName: '自定义表单',
		},
	},
];

export default routes;