import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import api from '../src/api/api.js'

Vue.use(ElementUI);
Vue.prototype.api = api;
// Vue.use({
//     install: (vm) => {
//         vm.prototype.api = api
//     }
// });

Vue.config.productionTip = false

import mixin from '@/core/mixin';
Vue.mixin(mixin);

import MenuSvgMixin from '@/components/Layout/SideMenu/assets/icon.mixin';
Vue.mixin(MenuSvgMixin);

import core from '@/core';
Vue.use(core);

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
