// 子应用引入
import Vue from 'vue';
/** 自定义表单 */
import productCustomFormView from "product-custom-form-view";
// import { addComplexComponent } from "product-custom-form-view";
Vue.use(productCustomFormView);
// addComplexComponent({
// 	label: '委托详情表格',
// 	tag: 'EntrustTable',
// 	tagIcon: 'table-icon',
// 	defaultValue: []
// });

/**  */