
/** 自定义表单 路由 */
import productCustomFormViewRoutes from './product-custom-form-view';

/** 数据集 路由 */
// import productDatasetViewRoutes from './product-dataset-view';

/** 模板管理 路由 */
import productTemplateViewRoutes from './product-template-view';

/** 流程 路由 */
import productSmartFlowViewRoutes from './product-smartflow-view';


const routes = [
	...productTemplateViewRoutes,
	// ...productDatasetViewRoutes,
	...productCustomFormViewRoutes,
	...productSmartFlowViewRoutes,
];

export default routes;

