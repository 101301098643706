import Vue from 'vue';
// import productTemplateView from "../../../../product-template-view";
import productTemplateView from "product-template-view";
Vue.use(productTemplateView);

// import { Index, TemplateConfig, TemplateBusiness } from "../../../../product-template-view";
import { Index, TemplateConfig, TemplateBusiness } from "product-template-view";

/** 模板管理 */
const routes = [
	{
		path: "/common-template",
		name: "CommonTemplate",
		component: Index,
		meta: {
			title: "通用模板",
			groupName: '模板',
			icon: "HomePage",
			hideMenu: true,
			type: 'commonTemplate',
		},
	},
	{
		path: "/project-template",
		name: "ProjectTemplate",
		component: Index,
		meta: {
			title: "项目模板",
			groupName: '模板',
			icon: "HomePage",
			hideMenu: true,
			type: 'projectTemplate',
		},
	},
	{
		path: "/other-template",
		name: "OtherTemplate",
		component: Index,
		meta: {
			title: "其他模板",
			groupName: '模板',
			icon: "HomePage",
			hideMenu: true,
			type: 'otherTemplate',
		},
	},
	{
		path: "/template-config",
		name: "TemplateConfig",
		component: TemplateConfig,
		meta: {
			title: "模板配置",
			hideMenu: true,
			currentActiveMenu: ['/common-template', '/project-template', '/other-template'],
			backPath: 'template-business',
		},
	},
	{
		path: "/doc-structure",
		name: "DocStructure",
		component: Index,
		meta: {
			title: "结构",
			groupName: '文档',
			icon: "HomePage",
			// icon: "docStructure",
            type: 'docStructure',
		},
	},
	{
		path: "/template-business",
		name: "TemplateBusiness",
		// component: TemplateBusiness,
		component: Index,
		meta: {
			title: "模板",
			groupName: '文档',
			icon: "HomePage",
		},
	},
	{
		path: "/template-business/:type",
		name: "TemplateBusinessType",
		component: Index,
		meta: {
			title: "模板",
			hideMenu: true,
			currentActiveMenu: '/template-business',
		},
	},
];

export default routes;